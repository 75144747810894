import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

export const ApprovedTrainer = ({
  getAllDistrictData,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getAllTrainingModuleData,
  FetchApprovedTrainingData,
  FetchAllApprovedTrainingData,
  getDistrictWiseApprovalList,
  userDetails,
  getDistrictWiseApprovalListForTrainer
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [ModuleData, setModuleData] = useState([])
  const [type, setType] = useState("");
  const [TalukaData, setTalukaData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [PanchayatData, setPanchayatData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = useState()
  const [filterString, setFilterString] = useState()
  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateDate = () => {
    let IsformValid = true;

    if (!city) {
      IsformValid = false;
      setcityError(true);
    }
    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }
    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true);
    }
    if (!type) {
      IsformValid = false;
      setTypeError(true);
    }

    return IsformValid;
  };

  const getAllList = async () => {
    // const result = await FetchAllApprovedTrainingData()
    // const result = await getDistrictWiseApprovalList({
    const result = await getDistrictWiseApprovalListForTrainer({
      "trainer_id": userDetails?._id,
      "distric_id": userDetails?.preferredDistrict_id,
    })
    if (result) {
      setTrainerData(result)
      setFilteredData(result)
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  //GET Trianer data
  const getTrainerData = async () => {
    setcityError(false);
    setTalukaError(false);
    setPanchayatError(false);
    setTypeError(false);

    if (validateDate()) {
      const result = await FetchApprovedTrainingData({
        "trainingModule_id": type?._id,
        "distric_id": city?._id,
        "grampanchayat_id": panchayat?._id
      })
      if (result) {
        setTrainerData(result)
      }
    }
  };
  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getTaluka = async () => {
    setTalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: city?._id
    })
    if (result !== 'No data found') {
      setTalukaData(result)
    } else {
      setTalukaError(true)
    }
  }

  const getPanchayat = async () => {
    setPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: city?._id,
      taluka_id: taluka?._id
    })
    if (result !== 'No data found') {
      setPanchayatData(result)
    } else {
      setPanchayatError(true)
    }
  }

  const getModule = async () => {
    const result = await getAllTrainingModuleData()
    if (result !== 'No data found') {
      setModuleData(result)
    }
  }


  useEffect(() => {
    setLoader(true)
    getDistrict();
    getModule();
    getAllList()
  }, []);

  useEffect(() => {
    if (city) {
      getTaluka()
    }
  }, [city])

  useEffect(() => {
    if (taluka) {
      getPanchayat()
    }
  }, [taluka])

  const filterData = (char) => {
    // console.log(char)
    if(filteredData != 'No data found'){
    setLoader(true);

      let f = filteredData?.filter(item => item?.trainerNameAll[0]?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainerNameAll[1]?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainerMobileAll[0]?.toString()?.startsWith(char?.toLowerCase()) ||
      item?.trainerMobileAll[1]?.toString()?.startsWith(char?.toLowerCase()) ||
      item?.districtName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.grampanchayatName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.shift?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.trainingModuleName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()))
    setTrainerData(f)
    setLoader(false);
    }
  }

  return (
    <div className={classes.main}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >Approved Training Document</Typography>
      </div>
      {/* <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}> */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: "wrap", marginBottom: 50 }}>
        {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-Bank"
            label="District"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {cityData &&
              cityData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select City
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-Bank"
            label="Taluka"
            value={taluka}
            disabled={TalukaData.length === 0 }
            onChange={(e) => {
              setTaluka(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {TalukaData &&
              TalukaData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.talukaName}
                  </MenuItem>
                );
              })}
          </Select>
          {TalukaError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Taluka
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Panchayat</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Panchayat"
            value={panchayat}
            disabled={ PanchayatData.length === 0 }
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {PanchayatData &&
              PanchayatData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.grampanchayatName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Panchyat
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Training Module</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Training Module"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {ModuleData &&
              ModuleData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.trainingModuleName}
                  </MenuItem>
                );
              })}
          </Select>
          {typeError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Training Module
            </span>
          ) : null}
        </FormControl>
        <Button
          sx={{ m: 1, marginRight: 3 }}
          variant="contained"
          style={{ fontSize: 14 }}
          onClick={() => getTrainerData()}
        >
          Get Data
        </Button> */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <TextField
            style={{ width: 300 }}
            // error={TrainerName.match(/[0-9+@#$&%!~]/)}
            id="outlined-adornment-TrainerName"
            label="Filter"
            value={filterString}
            onChange={(e) => { setFilterString(e.target.value); filterData(e.target.value) }}
          // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
          />
        </FormControl>
      </div>
      {loader ? <CircularProgress color="primary" /> : <Paper sx={{ overflow: "hidden", width: "90%" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>SR No.</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Name</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>District</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>School</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Training Module</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Training Start Date</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Timing</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainerData !== 'No data found' && trainerData.length > 0 ? (
                trainerData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerName}
                        </TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                          {row?.districtName}
                        </TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                          {row?.grampanchayatName}
                        </TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                          {row?.trainingModuleName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {moment(row?.startDate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                          {row?.shift ? row?.shift?.toUpperCase() : '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate("/trainerLayout/viewApprovedItem", {
                                state: row,
                              })
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>NO Data Found</TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 80,
    height: 80,
  },
  imageApprove: {
    width: 80,
    height: 80,
    border: "5px solid green",
  },
  imageReject: {
    width: 80,
    height: 80,
    border: "5px solid red",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    img1: state.admin.img1,
    img2: state.admin.img2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    FetchApprovedTrainingData: (data) => dispatch(Action.FetchApprovedTrainingData(data)),
    FetchAllApprovedTrainingData: () => dispatch(Action.FetchAllApprovedTrainingData()),
    getDistrictWiseApprovalList: (data) => dispatch(Action.getDistrictWiseApprovalList(data)),
    getDistrictWiseApprovalListForTrainer: (data) => dispatch(Action.getDistrictWiseApprovalListForTrainer(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedTrainer);
