import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  Card,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useEffect } from "react";
import axios from "axios";
import { display } from "@mui/system";
import dayjs from "dayjs";
import { BASE_URL } from "../../utils/urls";

export const AllGrampanchyatList = ({
  getAllDistrictData,
  getAllTalukaData,
  getAllgrampanchayatdata,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getDistrictCollectorList
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //Modal variables
  const [AddPanchyat, setAddPanchyat] = useState(false);
  const [EditPanchyat, setEditPanchyat] = useState(false);
  const [ViewPanchayat, setViewPanchayat] = useState(false);
  const [DistrictData, setDistrictData] = useState([]);
  const [TalukaData, setTalukaData] = useState([]);
  const [Village, setVillage] = useState([]);

  const [ViewData, setViewData] = useState([]);
  const [loading, setloading] = useState(false);
  const [deleteGrampanchayat, setDeleteGramPanchayat] = useState();
  const [grampanchayat_id, setId] = useState();
  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

 

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //data variables
  const [District, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [dcList, setDcList] = useState([]);
  const [schoolId, setSchoolId] = useState("");
  const [GramPanchayat, setGramPanchayat] = useState("");

  const [area, setArea] = useState("");
  const [board, setBoard] = useState("");
  const [classBeingTarget, setClassBeingTarget] = useState("");
  const [principal, setPrincipal] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [coordinatorMobile, setCoordinatorMobile] = useState("");
  const [representative, setRepresentative] = useState("");
  const [gmapLink, setGmapLink] = useState("");
  const [address, setAddress] = useState("");

  const [desc, setDesc] = useState("");

  console.log("payload", GramPanchayat, District, taluka, area, board, classBeingTarget, coordinator, coordinatorMobile, representative, gmapLink, address, principal);
  const [loader, setLoader] = useState(false);



  //Error variables
  const [panchyatError, setpanchyatError] = useState(false);
  const [districError, setdistricError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [boardError, setBoardError] = useState(false);
  const [classBeingTargetError, setClassBeingTargetError] = useState(false);
  const [principalError, setPrincipalError] = useState("");
  const [coordinatorError, setCoordinatorError] = useState(false);
  const [coordinatorMobileError, setCoordinatorMobileError] = useState(false);
  const [representativeError, setRepresentativeError] = useState(false);
  const [gmapLinkError, setGmapLinkError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [error, setError] = useState(false);

  //filter
  const [filteredData, setFilteredData] = useState()
  const [filterString, setFilterString] = useState()

  console.log("Village", Village);


  useEffect(() => {
    if (District) {
      getTaluka()
    }
  }, [District])

  useEffect(() => {
    if (District) {
      getTaluka()
    }
  }, [District])

  const validate = () => {
    let IsformValid = true;

    if (!GramPanchayat) {
      IsformValid = false;
      setpanchyatError(true);
    } else if (GramPanchayat.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!District) {
      IsformValid = false;
      setdistricError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }

    if (!area) {
      IsformValid = false;
      setAreaError(true);
    }

    if (!board) {
      IsformValid = false;
      setBoardError(true);
    }

    if (!classBeingTarget) {
      IsformValid = false;
      setClassBeingTargetError(true);
    }

    if (!coordinator) {
      IsformValid = false;
      setCoordinatorError(true);
    }

    if (!coordinatorMobile) {
      IsformValid = false;
      setCoordinatorMobileError(true);
    }

    if (!representative) {
      IsformValid = false;
      setRepresentativeError(true);
    }

    
    if (!address) {
      IsformValid = false;
      setAddressError(true);
    }

    if (!gmapLink) {
      IsformValid = false;
      setGmapLinkError(true);
    }

    return IsformValid;
  };

  const openPanchyat = () => {
    setAddPanchyat(true);
  };

  const closeModal = () =>{

    setGramPanchayat("");
    setDistrict("");
    setTaluka("");
    setArea("");
    setBoard("");
    setClassBeingTarget("");
    setPrincipal("");
    setCoordinator("");
    setCoordinatorMobile("");
    setRepresentative("");
    setGmapLink("");
    setAddress("");
  }

  const handleSubmit = async () => {

    setdistricError(false);
    setTalukaError(false);
    setAddressError(false);

    if (validate()) {
      const SchoolName = GramPanchayat;
      // const description = desc;
      const districtName = District?.districtName;
      const distric_id = District?._id;
      const taluka_id = taluka?._id;
      const talukaName = taluka?.talukaName;
      const Area = area;
      const Board = board;
      const ClassBeingTarget = classBeingTarget;
      const SchoolCoordinatorName = coordinator;
      const MobileNo = coordinatorMobile;
      const SakalRepresentative = representative?.firstName + " " + representative?.lastName;
      const Address = address;
      const GoogleMapLink = gmapLink;
      const PrincipleName = principal;
      
      await axios
        .post(
          BASE_URL + "/school/insertschool",
          {
            SchoolName,
            taluka_id,
            talukaName,
            distric_id,
            districtName,
            Area,
            Board,
            ClassBeingTarget,
            SchoolCoordinatorName,
            MobileNo,
            SakalRepresentative,
            Address,
            GoogleMapLink,
            PrincipleName
          }
        )
        .then((responce) =>
          {

          }
        )
        .catch((err) => {
          // console.log("error from handleSubmit Allgrampanchayt ", err);
        });
      getGrampanchayat();
      setAddPanchyat(false);
      closeModal();
    } else {
      // console.log("Form Invalid");
    }
  };

  const EdithandleSubmit = async () => {
    setpanchyatError(false);
    setdistricError(false);
    setTalukaError(false);
    // console.log(EditData);
    const schoolName = GramPanchayat;
    const Area = area;
    const Board = board;
    const ClassBeingTarget = classBeingTarget;
    const SchoolCoordinatorName = coordinator;
    const MobileNo = coordinatorMobile;
    const SakalRepresentative = representative?.firstName + " " + representative?.lastName;
    const GoogleMapLink = gmapLink;
    

    if (validate()) {
      await axios
        .put(
          BASE_URL + "/school/schoolupdate",
          {
            schoolId,
            schoolName,
            Area,
            Board,
            ClassBeingTarget,
            SchoolCoordinatorName,
            MobileNo,
            SakalRepresentative,
            address,
            GoogleMapLink
          }
        )
        .then((responce) =>
          {}
        )
        .catch((err) => {
          // console.log("error from EdithandleSubmit Allgrampanchayt ", err);
        });
      getGrampanchayat();
      setEditPanchyat(false);
      closeModal();
    } else {
      // console.log("Form Invalid");
    }
  };

  const openEdit = (row) => {
    console.log("Editttt",row)
    setSchoolId(row?._id);
    setGramPanchayat(row?.schoolName);
    setTaluka(row?.talukaName);
    setDistrict(row?.districtName);
    setArea(row?.otherInfo?.Area);
    setBoard(row?.otherInfo?.Board);
    setClassBeingTarget(row?.otherInfo?.ClassBeingTarget);
    setCoordinator(row?.otherInfo?.SchoolCoordinatorName);
    setCoordinatorMobile(row?.otherInfo?.MobileNo);
    setRepresentative(row?.otherInfo?.SakalRepresentative);
    setGmapLink(row?.otherInfo?.GoogleMapLink);
    setAddress(row?.otherInfo?.GoogleMapLink);

    setEditPanchyat(true);
  };
  const openView = (row) => {
    setViewData(row);
    // console.log("EditData", EditData);
    setViewPanchayat(true);
  };

  const openDelete = (row) => {
    setId(row?._id);

    setDeleteGramPanchayat(true);
  };
  const DeleteVillage = async () => {
    await axios
      .put(
        BASE_URL + "/school/deleteschool",
        { School_id:grampanchayat_id }
      )
      .then((responce) => {
        getGrampanchayat();
        // GetAllVillage();
      })
      .catch((err) => {
        // console.log("error from DeleteVillage AllDistrictlist component", err);
      });
  };

  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setDistrictData(result);
      // setDistrictData(result);
    }
  };

  const getTaluka = async () => {
    const result = await getDistrictWiseTalukaData({
      distric_id: District?._id
    });

    if (result !== "No data found") {
      setTalukaData(result);
      // setTalukaData(result);
    }
  };

  const getGrampanchayat = async () => {
    setLoader(true)
    const result = await getAllgrampanchayatdata();
    if (result !== "No data found") {
      setVillage(result);
      setFilteredData(result)
      setLoader(false)
    } else {
      setLoader(false)
    }
  };
  useEffect(() => {
    getDistrict();
    getGrampanchayat();
    getDC();
  }, []);

  const getDC = async () => {
    setLoader(true)
    const result = await getDistrictCollectorList();
    if (result) {
        setDcList(result)
    }else{
        setDcList([]);
    }
}

  const getSelectValidate = () => {

    let IsformValid = true;

    if (!District) {
      IsformValid = false;
      setdistricError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }

    return IsformValid;

  }

  const getPanchayat = async () => {
    setdistricError(false)
    setTalukaError(false)
    if (getSelectValidate()) {
      const result = await getTalukaWisePanchayat({
        distric_id: District?._id,
        taluka_id: taluka?._id
      })
      if (result !== 'No data found') {

        setVillage(result)
      }
    }
  }

  const filterData = (char) => {
    setLoader(true);
    // console.log(char)
    // console.log("Charracter Array", filteredData)
    let f = filteredData?.filter(item => item?.schoolName?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.districtName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.talukaName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      // dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.description?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.sarpanchName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
      item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
      )
    setVillage(f)
    // console.log("MyData", f)
    setLoader(false);
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            height: "100vh",
            // width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <div className={classes.main}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Box sx={{ flex: 1 }}>
              <h3>School Data</h3>
            </Box>
            <Box>
              <div style={{ marginTop: 30, right: 0 }}>
                <Button variant="contained" onClick={() => openPanchyat()}>
                  <PersonAddRounded style={{ marginRight: 10 }} />
                  Add School
                </Button>
              </div>
            </Box>
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
            {/* <FormControl sx={{ m: 1, marginRight: 1 }}>
              <InputLabel id="outlined-adornment-Bank">District</InputLabel>
              <Select
                style={{ width: 250 }}
                id="outlined-adornment-Bank"
                label="District"
                value={District}
                onChange={(e) => {
                  setDistrict(e.target.value);
                }}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
              >
                {DistrictData &&
                  DistrictData.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.districtName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {districError ? (
                <span style={{ color: "red", fontSize: 15 }}>
                  *Please Select District
                </span>
              ) : null}
            </FormControl>
            <FormControl sx={{ m: 1, marginRight: 1 }}>
              <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
              <Select
                style={{ width: 250 }}
                id="outlined-adornment-Bank"
                label="Taluka"
                value={taluka}
                onChange={(e) => {
                  setTaluka(e.target.value);
                }}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                  },
                }}
              >
                {TalukaData &&
                  TalukaData.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.talukaName}
                      </MenuItem>
                    );
                  })}
              </Select>
              {TalukaError ? (
                <span style={{ color: "red", fontSize: 15 }}>
                  *Please Select Taluka
                </span>
              ) : null}
            </FormControl>
            <Button
              sx={{ m: 1, marginRight: 1 }}
              variant="contained"
              style={{ fontSize: 14 }}
              onClick={() => getPanchayat()}
            >
              Get Data
            </Button>
            <Button
              sx={{ m: 1, marginRight: 1 }}
              variant="contained"
              style={{ fontSize: 14 }}
              onClick={() => getGrampanchayat()}
            >
              Get All Data
            </Button> */}
            <FormControl sx={{ m: 1, marginRight: 3 }}>
              <TextField
                style={{ width: 300 }}
                // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                id="outlined-adornment-TrainerName"
                label="Filter"
                value={filterString}
                onChange={(e) => { setFilterString(e.target.value); filterData(e.target.value) }}
              // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
              />
            </FormControl>
          </div>

          {
            loader ? <CircularProgress color="primary" /> : <Paper sx={{ overflow: "hidden", margin: 2 }}>
              <TableContainer>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>School Name</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>District Name</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Taluka Name</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Area</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Board</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { Village && Village.length > 0 ? (
                    Village.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            style={{ fontSize: 16, textAlign: "center" }}>
                            {row?.schoolName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: 16, textAlign: "center" }}>
                            {row?.districtName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: 16, textAlign: "center" }} >
                            {row?.talukaName}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: 16, textAlign: "center" }}>
                            {row?.otherInfo?.Area}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: 16, textAlign: "center" }}>
                            {row?.otherInfo?.Board}
                          </TableCell>
        
                          <TableCell style={{ textAlign: "center" }}>
                            <Button
                              variant="contained"
                              style={{ margin: 5 }}
                              onClick={() => openEdit(row)}
                            >
                              <Edit style={{ marginRight: 5 }} />
                              {/* Edit */}
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                // DeleteVillage(row);
                                openDelete(row);
                              }}
                              style={{ margin: 5 }}
                            >
                              <Delete style={{ marginRight: 5 }} />
                              {/* Delete */}
                            </Button>
                            <Button
                              variant="contained"
                              style={{ margin: 5 }}
                              onClick={() => openView(row)}
                            >
                              <Visibility style={{ marginRight: 3 }} />
                              {/* View */}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>NO Data Found</TableRow>
                  )}
                </TableBody>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                // count={count ? count : 0}
                count={ Village && Village !== 'No data found' && Village?.length > 0 ? Village?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          }

          {/* Add GramPanchayat */}
          <Modal
            className={classes.middlePosition}
            open={AddPanchyat}
            onClose={(e) => {
              e.preventDefault();
              closeModal();
              setAddPanchyat(false);
              
            }}
          >
            <Paper className={classes.profilemodal}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="column">
                  <Typography variant="h6" component="div">
                    Add School
                  </Typography>
                </Stack>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                    setAddPanchyat(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    width: "90%",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}>

                  <div style={{ display: "flex", flexDirection: "row" }}>

                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 500 }}
                        error={GramPanchayat?.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="School Name"
                        value={GramPanchayat}
                        onChange={(e) => setGramPanchayat(e.target.value)}
                        helperText={
                          panchyatError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter School Name
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

            
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        District
                      </InputLabel>
                      <Select
                        style={{ width: 250 }}
                        id="outlined-adornment-bank"
                        label="District"
                        value={District}
                        onChange={(e) => setDistrict(e.target.value)}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                        disabled={ DistrictData === undefined || DistrictData.length === 0 }
                      >
                        { DistrictData && DistrictData !== "No data  found" &&
                          DistrictData.length > 0
                          ? DistrictData.map((option) => (
                            <MenuItem key={option?._id} value={option}>
                              {option?.districtName}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                      {districError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Select District
                        </span>
                      ) : null}
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        Taluka
                      </InputLabel>
                      <Select
                        style={{ width: 250 }}
                        id="outlined-adornment-bank"
                        label="Taluka"
                        value={taluka}
                        onChange={(e) => setTaluka(e.target.value)}
                        disabled={TalukaData.length === 0}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        { TalukaData && TalukaData !== "No data  found" &&
                          TalukaData.length > 0
                          ? TalukaData.map((option) => (
                            <MenuItem key={option?._id} value={option}>
                              {option?.talukaName}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                      {TalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Select Taluka
                        </span>
                      ) : null}
                    </FormControl>

                   
                  </div>


                  <div style={{ display: "flex", flexDirection: "row" }}>

                  <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Area"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                        helperText={
                          areaError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Area
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Board"
                        value={board}
                        onChange={(e) => setBoard(e.target.value)}
                        helperText={
                          boardError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Board
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Class Being Targets"
                        value={classBeingTarget}
                        onChange={(e) => setClassBeingTarget(e.target.value)}
                        helperText={
                          classBeingTargetError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter classes
                            </span>
                          ) : null
                        }/>
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Principal Name"
                        value={principal}
                        onChange={(e) => setPrincipal(e.target.value)}
                        />
                    </FormControl>
                  
                    </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Cordinator Name"
                        value={coordinator}
                        onChange={(e) => setCoordinator(e.target.value)}
                        helperText={
                          coordinatorError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Cordinator Name
                            </span>
                          ) : null
                        }/>
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 4 }}>
                      <TextField
                        style={{ width: 250 }}
                        error={
                          (coordinatorMobile?.length !== 10 && coordinatorMobile?.length !== 0)
                        }
                        id="outlined-adornment-TrainerName"
                        label="School Cordinator Mobile"
                        value={coordinatorMobile}
                        onChange={(e) => setCoordinatorMobile(e.target.value)}
                        helperText={
                          coordinatorMobileError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Cordinator Mobile
                            </span>
                          ) : null
                        }/>
                    </FormControl>

              <div>
                <Autocomplete
                id="Trainer-data"
                sx={{ width: 250, m:1 }}
                options={dcList}
                autoHighlight
                disablePortal
                disabled={dcList === undefined || dcList.length === 0}
                getOptionLabel={(option) =>
                  option?.firstName +
                  " " +
                  option?.lastName +
                  " - " +
                  option?.contactNo
            }
            renderInput={(params) => (
              <TextField {...params} label="Sakal Representative" />
            )}
            // value={searchTrainer}
            onChange={(event, newValue) => {
              setRepresentative(newValue);
              // getSingleUser(newValue)
            }}
          />
                      {representativeError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Select Sakal Representative
                        </span>
                      ) : null}

                </div>

                <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        helperText={
                          addressError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter School Address
                            </span>
                          ) : null
                        }
                        />
                    </FormControl>

                    
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                    

                    <FormControl sx={{ m: 1 }}>
                      <TextField
                        style={{ width: 500 }}
                        id="outlined-adornment-TrainerName"
                        label="Google Map Link"
                        value={gmapLink}
                        onChange={(e) => setGmapLink(e.target.value)}
                        helperText={
                          gmapLinkError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Google Map Link
                            </span>
                          ) : null
                        }
                        />
                    </FormControl>
                      </div>

                    


                </CardContent>

                {error ? (
                  <Alert
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please fill requested form properly
                  </Alert>
                ) : null}
                <Button
                  variant="contained"
                  style={{ margin: 10 }}
                  endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Paper>
          </Modal>

          {/* Edit Panchyat */}
          <Modal
            className={classes.middlePosition}
            open={EditPanchyat}
            onClose={(e) => {
              e.preventDefault();
              setEditPanchyat(false);
              closeModal();
            }}
          >
            <Paper className={classes.profilemodal}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column">
                  <Typography variant="h6" component="div">
                    Edit School
                  </Typography>
                </Stack>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                    setEditPanchyat(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    width: "90%",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 500 }}
                        error={GramPanchayat?.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="School Name"
                        value={GramPanchayat}
                        disabled={true}
                        onChange={(e) => setGramPanchayat(e.target.value)}
                        helperText={
                          panchyatError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter School Name
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Destrict"
                        value={District}
                        disabled={true}
                        // onChange={(e) => setDesc(e.target.value)}
                        helperText={
                          districError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please select district
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        // error={taluka.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Taluka"
                        value={taluka}
                        disabled={true}
                        // onChange={(e) => setDesc(e.target.value)}
                        helperText={
                          TalukaError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please select taluka
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

               

                   
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>

                  <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Area"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                        helperText={
                          areaError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Area
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    
                  <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Board"
                        value={board}
                        onChange={(e) => setBoard(e.target.value)}
                        helperText={
                          boardError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Board
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Class Being Targets"
                        value={classBeingTarget}
                        onChange={(e) => setClassBeingTarget(e.target.value)}
                        helperText={
                          classBeingTargetError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter classes
                            </span>
                          ) : null
                        }/>
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Principal Name"
                        value={principal}
                        onChange={(e) => setPrincipal(e.target.value)}
                        />
                    </FormControl>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Cordinator Name"
                        value={coordinator}
                        onChange={(e) => setCoordinator(e.target.value)}
                        helperText={
                          coordinatorError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Cordinator Name
                            </span>
                          ) : null
                        }/>
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 4 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="School Cordinator Mobile"
                        value={coordinatorMobile}
                        onChange={(e) => setCoordinatorMobile(e.target.value)}
                        helperText={
                          coordinatorMobileError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Cordinator Mobile
                            </span>
                          ) : null
                        }/>
                    </FormControl>

              <div>
                <Autocomplete
                id="Trainer-data"
                sx={{ width: 250, m:1 }}
                options={dcList}
                autoHighlight
                disablePortal
                disabled={dcList === undefined || dcList.length === 0}
                getOptionLabel={(option) =>
                  option?.firstName +
                  " " +
                  option?.lastName +
                  " - " +
                  option?.contactNo
            }
            renderInput={(params) => (
              <TextField {...params} label="Sakal Representative" />
            )}
            // value={searchTrainer}
            onChange={(event, newValue) => {
              setRepresentative(newValue);
              // getSingleUser(newValue)
            }}
          />
                      {representativeError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Select Sakal Representative
                        </span>
                      ) : null}
                    </div>

                    <FormControl sx={{ m: 1, marginRight: 5 }}>
                      <TextField
                        style={{ width: 250 }}
                        id="outlined-adornment-TrainerName"
                        label="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        helperText={
                          addressError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Address
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    </div>
                  
                    <div style={{ display: "flex", flexDirection: "row" }}>
                    

                    <FormControl sx={{ m: 1 }}>
                      <TextField
                        style={{ width: 500 }}
                        id="outlined-adornment-TrainerName"
                        label="Google Map Link"
                        value={gmapLink}
                        onChange={(e) => setGmapLink(e.target.value)}
                        helperText={
                          gmapLinkError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Google Map Link
                            </span>
                          ) : null
                        }
                        />
                    </FormControl>
                      </div>
                </CardContent>
                {error ? (
                  <Alert
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please fill requested form properly
                  </Alert>
                ) : null}
                <Button
                  variant="contained"
                  style={{ margin: 10 }}
                  endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    EdithandleSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Paper>
          </Modal>

          {/* view model */}
          <Modal
            className={classes.middlePosition}
            open={ViewPanchayat}
            onClose={(e) => {
              e.preventDefault();
              setViewPanchayat(false);
            }}
          >
            <Paper className={classes.profilemodal}>
              <Stack
                //direction="row"
                // justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack>
                </Stack>
                <Stack>
                  <Card
                    className={classes.card}
                    style={{ backgroundColor: "whitesmoke" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d5a96",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        margin: 0,
                      }}
                    >
                      <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: 700,
                            color: "white",
                            fontSize: 24,
                            backgroundColor: "#1d5a96",
                          }}
                          sx={{}}
                        >
                          School Data
                        </Typography>
                      </IconButton>
                    </div>
                    <div>
                      <Table>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            School Name
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.schoolName}
                          </TableCell>
                        </TableRow>
              
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            District Name
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.districtName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Taluka Name
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.talukaName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Area
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.otherInfo?.Area}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Board
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.otherInfo?.Board}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            School Coordinator
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.otherInfo?.SchoolCoordinatorName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Address
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.otherInfo?.Address} {ViewData?.otherInfo?.GoogleMapLink && <a style={{marginLeft:'6px'}} target="_blank" href={ViewData?.otherInfo?.GoogleMapLink}>See Location</a>}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                    <center>
                      <Button
                        variant="contained"
                        style={{ margin: 20 }}
                        onClick={() => setViewPanchayat(false)}>
                        Back
                      </Button>
                    </center>
                  </Card>
                </Stack>
              </Stack>
            </Paper>
          </Modal>
          {/* delete model  */}

          <Modal
            className={classes.middlePosition}
            open={deleteGrampanchayat}
            onClose={(e) => {
              e.preventDefault();
              setDeleteGramPanchayat(false);
            }}
          >
            <Paper className={classes.profilemodal}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    justifyContent={"center"}
                    component="div"
                  >
                    Are You Sure You Want To Delete ?
                  </Typography>
                </Stack>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  margin: "21px",
                }}
              >
                <Button
                  variant="contained"
                  style={{ margin: 10, backgroundColor: "green" }}
                  // endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    // EdithandleSubmit();
                    setDeleteGramPanchayat(false);
                  }}
                >
                  Cancle
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: 10, backgroundColor: "red" }}
                  // endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    DeleteVillage();
                    setDeleteGramPanchayat(false);
                    // DeleteTaluka()
                    // setDeleteTaluka(false)
                  }}
                >
                  <Delete style={{ marginRight: 5 }} />
                  Delete
                </Button>
              </div>
            </Paper>
          </Modal>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    // padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    // width: "1000px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    marginTop: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getAllgrampanchayatdata: () => dispatch(Action.getAllgrampanchayatdata()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
    getDistrictCollectorList: () => dispatch(Action.getDistrictCollectorList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllGrampanchyatList);
