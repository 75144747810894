import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Badge, Tooltip, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Avatar, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import TextsmsIcon from '@mui/icons-material/Textsms';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
// import DoneIcon from '@mui/icons-material/Done';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useLocation, useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete, Category, Start } from "@mui/icons-material";
import React, { useState } from 'react';
import dayjs from "dayjs";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import img1 from '../../Assets/Image/nope-not-here.webp'
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BASE_URL } from '../../utils/urls';
import moment from 'moment/moment';
import docImg from '../../Assets/Image/doc.png'
import videoImg from '../../Assets/Image/Video.png'


require('../../App.css')


const TrainerData = [
  {
    Gram_Panchayat: 'GP_1',
    Assignment_Date: '12/12/2022',
    Shift: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'GP_1',
    Assignment_Date: '12/12/2022',
    Shift: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
  {
    Gram_Panchayat: 'GP_1',
    Assignment_Date: '12/12/2022',
    Shift: 'Morning',
    Module_Desc: 'xyz',
    Trainer_Name: 'abc',
    Trainer_Mobile: 8232988832,
  },
]
const cityData = [
  {
    label: 'Akola',
    value: 'Akola'
  },
  {
    label: 'Pune',
    value: 'Pune'
  },
  {
    label: 'Beed',
    value: 'Beed'
  },
  {
    label: 'Kolhapur',
    value: 'Kolhapur'
  },
  {
    label: 'Mumbai',
    value: 'Mumbai'
  },
  {
    label: 'Nagpur',
    value: 'Nagpur'
  },
  {
    label: 'Sangali',
    value: 'Sangali'
  },
]

const TalukaData = [
  {
    label: 'Ambegoan',
    value: 'Ambegoan'
  },
  {
    label: 'Baramati',
    value: 'Baramati'
  },
  {
    label: 'Bhor',
    value: 'Bhor'
  },
  {
    label: 'Daund',
    value: 'Daund'
  },
  {
    label: 'Haveli',
    value: 'Haveli'
  },
  {
    label: 'Khed',
    value: 'Khed'
  },
  {
    label: 'Mulshi',
    value: 'Mulshi'
  },
]

const PanchayatData = [
  {
    label: 'ADEGAON',
    value: 'ADEGAON'
  },
  {
    label: 'AGARGAON',
    value: 'AGARGAON'
  },
  {
    label: 'BAJARWADA',
    value: 'BAJARWADA'
  },
  {
    label: 'CHINCHALA',
    value: 'CHINCHALA'
  },
  {
    label: 'DHANORA',
    value: 'DHANORA'
  },
  {
    label: 'FARITPUR',
    value: 'FARITPUR'
  },
  {
    label: 'ITALAPUR',
    value: 'ITALAPUR'
  },

]

const activities = [
  'Progress',
  'Snacks',
  'Group Photo',
  'Study Material',
  'Notepad and Pen',
  'Attendance Sheet',
  'Feedback Form',
  'Training Outcome',
  'Training Agenda',
  'Study Material Proof',
  'Distribution of Ebook',
  'Beneficiary Book'
]

const Type = [
  {
    label: "type_1",
    value: "Type_1",
  },
  {
    label: "type_2",
    value: "type_2",
  },
  {
    label: "xyz",
    value: "xyz",
  },
]
const Session = [
  {
    label: "Morning",
    value: "morning",
  },
  {
    label: "Evening",
    value: "evening",
  },
]

const categories = [
  { name: "Start Progress", qty: 3, type: "image/*" },
  { name: "Mid Progress", qty: 3, type: "image/*" },
  { name: "End Progress", qty: 3, type: "image/*" },
  { name: "Snacks", qty: 3, type: "image/*" },
  { name: "Group Photo", qty: 3, type: "image/*" },
  { name: "Study Material", qty: 3, type: "image/*" },
  { name: "Notepad and Pen", qty: 3, type: "image/*" },
  { name: "Attendance Sheet", qty: 1, type: "image/*" },
  { name: "Feedback Form", qty: 1, type: ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
  { name: "Training Outcome", qty: 1, type: ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
  { name: "Training Agenda", qty: 1, type: ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
  { name: "Study Material Proof", qty: 3, type: "image/*" },
  { name: "Distribution of Ebook", qty: 1, type: ".xlsx,.xls,.csv,.pdf,.doc,.docx,application/msword," },
  { name: "Beneficiary Sheet", qty: 1, type: ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
  { name: "Video Testimonial", qty: 1, type: "video/*" },
]


/**
 * 
 * ****************************************************
 *                                                    *
 * THIS IS UPLOAD TRAINING DOCUMENT PAGE FOR TRAINERS *
 *                                                    *
 * ****************************************************
 * 
 */


/**
 * NOTE: Type, type setType means to be ModuleType 
 */

export const Activities = ({ getAllGP, userDetails, getAllTrainingModuleData,getSingleTraining }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const data = location.state
  // console.log("DATTATAA::::", data)
  // console.log("UserDetails+++++", userDetails)
  const trimmerRef = React.useRef();
  const [gp_id, setGp_id] = React.useState(data?.grampanchayat_id)
  const [module_id, setModule_id] = React.useState(data?.trainingModule_id)

  // Array Variable
  const [typeArray, setTypeArray] = useState([])
  const [gramPanchayatArray, setGramPanchayatArray] = useState([])
  const [categoryArray, setCategoryArray] = useState([])
  const [uploadDocList, setUploadDocList] = useState([])
  const [docList, setDocList] = useState([])
  const [tableRow, setTableRow] = useState()

  //data variables
  const [fromDate, setFromDate] = useState(data ? data.startDate : null)
  const [toDate, setToDate] = useState(data ? data.startDate : null)
  const [minimumDate, setMinimumDate] = useState('01/01/2000')
  const [panchayat, setPanchayat] = useState(data ? data.grampanchayatName : "")
  const [trainerData, setTrainerData] = useState([])
  const [type, setType] = useState(data ? data.trainingModuleName : "");
  const [session, setSession] = useState(data ? data.shift : "");
  const [activityData, setActivityData] = useState([]);
  const [trainingDate, setTrainingDate] = useState(data ? data.startDate : "");
  const [openModal, setOpenModal] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [imgUploading, setImgUploading] = useState(false);
  const [imgUploadStatus, setImgUploadStatus] = useState(false);
  const [imgUploadData, setImgUploadData] = useState();
  const [imgUploadingError, setImgUploadingError] = useState(false);
  const [imgArray, setImgArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [showComment, setShowComment] = useState('');
  const [uploadType, setUploadType] = useState();
  const [eventData, setEventData] = useState();
  const [approveRejectCount, setApproveRejectCount] = useState();

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Error Variables
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  // const renderCategory = categories.map((item, index) => <div key={index} className='statusCard' onClick={() => { setCategoryName(item); setOpenModal(!openModal) }}>{item.name}</div>)
  // ------------------------------------------------------------------------------------------
  // const renderCategory = categoryArray?.map((item, index) => <div key={index} className='statusCard' onClick={() => { fetchUploadedDocList(); setCategoryName(item); getCategoryDocType(item); }}>{item?.name}
  // <div>approved:{approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]!==undefined?approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]?.approved.length:"0"}</div>
  // <div>Rejected:{approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]!==undefined?approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]?.rejected.length:"0"}</div>
  // <div>Unprocessed:{approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]!==undefined?approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim())[0]?.unprocessed.length:"0"}</div></div>)
  // ------------------------------------------------------------------------------------
  // const renderCategory = categoryArray?.map((item, index) => <div key={index} className='statusCard' onClick={() => { setDocList(item?.categories);setCategoryName(item); fetchUploadedDocList(); setOpenModal(!openModal) }}>{item.name}</div>)



  const renderCategory = categoryArray?.map((item, index) => {
    // // console.log("OPOPOP", item?.minDocAproved, approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.approved.length);
    // if (item?.minDocAproved <= approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.approved.length) {
    if (item?.isApproved) {
      return (
        <div className='statusCardApprove' onClick={() => { fetchUploadedDocList(); setCategoryName(item); getCategoryDocType(item); }}>
          <div>
            <strong>{item?.name}</strong>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
            <div style={{ flex: 1 }}>
              <Tooltip title="Approved">
                <Badge color="success"
                  sx={{
                    "& .MuiBadge-badge": {
                      width: 30,
                      height: 30,
                      fontSize: 14,
                      borderRadius: '50%',
                    }
                  }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.approved.length.toString() : "0"} />
              </Tooltip>
            </div>
            <div style={{ flex: 1 }}>
              <Tooltip title="Rejected">
                <Badge color="error" sx={{
                  "& .MuiBadge-badge": {
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    borderRadius: '50%',
                  }
                }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.rejected.length.toString() : "0"} />
                {/* {// console.log("ALLLL",approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim()))} */}
              </Tooltip>
            </div>
            <div style={{ flex: 1 }}>
              <Tooltip title="Unprocess">
                <Badge sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    borderRadius: '50%',
                    backgroundColor: "#e9cd16"
                  }
                }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.unprocessed.length.toString() : "0"} />
              </Tooltip>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='statusCard' onClick={() => { fetchUploadedDocList(); setCategoryName(item); getCategoryDocType(item); }}>
          <div>
            <strong>{item?.name}</strong>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
            <div style={{ flex: 1 }}>
              <Tooltip title="Approved">
                <Badge color="success"
                  sx={{
                    "& .MuiBadge-badge": {
                      width: 30,
                      height: 30,
                      fontSize: 14,
                      borderRadius: '50%',
                    }
                  }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.approved.length.toString() : "0"} />
              </Tooltip>
            </div>
            <div style={{ flex: 1 }}>
              <Tooltip title="Rejected">
                <Badge color="error" sx={{
                  "& .MuiBadge-badge": {
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    borderRadius: '50%',
                  }
                }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.rejected.length.toString() : "0"} />
                {/* {// console.log("ALLLL",approveRejectCount?.filter(item2=>item2?._id.toLowerCase().trim()===item.name.toLowerCase().trim()))} */}
              </Tooltip>
            </div>
            <div style={{ flex: 1 }}>
              <Tooltip title="Unprocess">
                <Badge sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    borderRadius: '50%',
                    backgroundColor: "#e9cd16"
                  }
                }} style={{ margin: 15 }} badgeContent={approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0] !== undefined ? approveRejectCount?.filter(item2 => item2?._id.toLowerCase().trim() === item.name.toLowerCase().trim())[0]?.unprocessed.length.toString() : "0"} />
              </Tooltip>
            </div>
          </div>
        </div>
      )
    }
  }
  )

  const getTraining = async () => {
    setLoader(true)
    const result = await getSingleTraining({
      trainingAssignTrainer_id: data?._id
    })
    if (result !== "No data found" && result !== undefined) {
      // console.log("Training", result[0])
      setTableRow(result[0])
      setLoader(false)
    } else {
      setLoader(false)
    }
  }


  const validateDate = () => {

    let IsformValid = true;

    if (!fromDate) {
      IsformValid = false;
      setFromDateError(true);
    }
    if (!toDate) {
      IsformValid = false;
      setToDateError(true);
    }
    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true)
    }
    if (!type) {
      IsformValid = false;
      setTypeError(true);
    }
    if (!session) {
      IsformValid = false;
      setSessionError(true);
    }
    return IsformValid;
  }

  React.useEffect(async () => {
    // if(!tableRow){
    // await fetchCategory2();
    await fetchModule();
    await getAllGramPanchayat();
    await fetchUploadedDocList();
    getTraining()
    // }

    // data?.trainingModule_id data?.grampanchayat_id

  }, [])

  React.useEffect( async () => {
    if (tableRow)
    await getTrainerData();
  },[tableRow] )


  //GET Trianer data
  const getTrainerData = async () => {

    setFromDateError(false)
    setToDateError(false)
    setPanchayatError(false)
    setTypeError(false)
    setSessionError(false)

    if (validateDate()) {
      // console.log("Setting Trainer data")
      setActivityData(activities);
      setTrainerData(TrainerData);
      await fetchUploadedDocList();
      // fetchCategory();
      setShowCategory(true);

    }
  }

  // const showActivity = ()
  const handleChange = async (e) => {
    // console.log("Changing Profile pic", e.target.files)
    if (e.target.files.length > categoryName.qty)
      alert("Cannot upload more than 10 Images")
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      // console.log("Files Type", e.target.files[0], uploadType)

      for (let i = 0; i < e.target.files.length; i++) {
        // categoryName.type
        uploadImageToS3(e.target.files[i], uploadType);
      }
    }
  }

  const uploadImageToS3 = async (event, fileType) => {

    console.log("event", event);
    console.log("fileType", fileType);

    setImgUploading(true);
    // console.log("TABLE ROW:", tableRow)
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + tableRow?.districtName + "/" + tableRow?.talukaName + "/" + panchayat?.trim() + "/" + type?.trim().replace(/ /g, "_") + "/" + categoryName.name?.trim() + "/" + userDetails._id,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com"
    };

    let fileInput = false;
    const image = event;
    // console.log("image data before compression-------------- ", image);
    fileType = image?.type?.split('/')[0]
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      if (fileType === "image") {
        // console.log("inside IMAGE")

        new Compressor(image, {
          quality: 0.4,
          success: (compressedResult) => {
            let getTimeStamp = new Date().getTime();
            let newFileName = getTimeStamp + "_";
            // console.log("------- filename image upload --------", newFileName);
            const ReactS3Client = new S3(config);
            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then(async (res) => {
                // setImage(res.location);

                if (res.status === 204) {
                  setImgUploading(false);
                  // console.log("uploded image", res);
                  // console.log("image uploaded successfully!!!!");
                  await uploadDocToAPI(res.location, event);
                  imgArray.push(res.location);
                  setImgArray(imgArray);
                }
                else {
                  setImgUploadingError(true);
                }
                if (type === "ProfilePic") {
                  // if (image !== null && image !== userDetails?.profilePhotoURL) {
                  //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
                  // }
                } else if (type === "Logo1") {
                  // setlogo1(res.location)
                } else if (type === "Logo2") {
                  // setlogo2(res.location)
                }
              }).catch(err => {
                console.error(err);
              });
          },
        });
      } else if (fileType === "video") {
        // console.log("inside VIDEO")
        let getTimeStamp = new Date().getTime();
        let newFileName = getTimeStamp + "_";
        const ReactS3Client = new S3(config);

        ReactS3Client.uploadFile(image, newFileName)
          .then(async (res) => {
            // setImage(res.location);
            if (res.status === 204) {
              setImgUploading(false);
              // console.log("uploded image", res);
              // console.log("image uploaded successfully!!!!");
              await uploadDocToAPI(res.location, event);
              imgArray.push(res.location);
              setImgArray(imgArray);
            }
            else {
              setImgUploadingError(true);
            }
            if (type === "ProfilePic") {
              // if (image !== null && image !== userDetails?.profilePhotoURL) {
              //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
              // }
            } else if (type === "Logo1") {
              // setlogo1(res.location)
            } else if (type === "Logo2") {
              // setlogo2(res.location)
            }
          }).catch(err => {
            console.error(err);
          });
      } else {
        // console.log("inside ELSE")
        let getTimeStamp = new Date().getTime();
        let newFileName = getTimeStamp + "_." + image.name.split('.')[1];
        const ReactS3Client = new S3(config);

        ReactS3Client.uploadFile(image, newFileName)
          .then(async (res) => {
            // setImage(res.location);
            if (res.status === 204) {
              setImgUploading(false);
              // console.log("uploded image", res);
              // console.log("image uploaded successfully!!!!");
              await uploadDocToAPI(res.location, event);
              imgArray.push(res.location);
              setImgArray(imgArray);
            }
            else {
              setImgUploadingError(true);
            }
            if (type === "ProfilePic") {
              // if (image !== null && image !== userDetails?.profilePhotoURL) {
              //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
              // }
            } else if (type === "Logo1") {
              // setlogo1(res.location)
            } else if (type === "Logo2") {
              // setlogo2(res.location)
            }
          }).catch(err => {
            console.error(err);
          });
      }

      // console.log("--------------- Upload image -----------");
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleVideoEncode = React.useCallback(result => {
    // console.log("Encoding Result:", result);
  });

  const getAllGramPanchayat = async () => {
    setLoader(true)
    const allArray = [];
    const url = BASE_URL + "/trainingassigntrainer/trainingassigntrainer/grampanchayat/list";

    userDetails?.preferredDistrict_id.length > 0 && userDetails?.preferredDistrict_id.map((item, index) => {
      const payload = {
        trainer_id: userDetails?._id,
        distric_id: item,
      }
      const opt = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
      fetch(url, opt)
        .then(res => {
          if (res.status === 200)
            return res?.json();
          else {
            alert("Error while getting School !");
            userDetails?.preferredDistrict_id.length === index + 1 && setLoader(false);
          }
        })
        .then(data => {
          // console.log("Data:YYYYY::;", data)
          // setModuleRes(data);
          const arr = data !== "No data found" && data?.map(item => {
            return {
              label: item?.grampanchayatName,
              value: item?.grampanchayatName,
              _id: item?.grampanchayat_id,
              taluka_id: item?.taluka_id,
              talukaName: item?.talukaName,
              distric_id: item?.distric_id,
              districtName: item?.districtName,
            }
          })
          data !== undefined && data !== "No data found" && allArray.push(...arr)
          // console.log("Array::::-----", allArray)
          setGramPanchayatArray(allArray)
          userDetails?.preferredDistrict_id.length === index + 1 && setLoader(false);
        })
        .catch(err => {
          // console.log(err); alert("Error while getting Grampanchayat !");
          userDetails?.preferredDistrict_id.length === index + 1 && setLoader(false);
        })
    })




    // --------------------------------------------------------------------------------------
    // let res = await getAllGP();
    // // console.log("GPLIST", res)
    // // console.log("GGGGGGGGGG", res.length)
    // let arr = res !== undefined && res.length > 0 ? res.map((item) => {
    //   return {
    //     label: item?.grampanchayatName,
    //     value: item?.grampanchayatName,
    //     _id: item?._id,
    //     taluka_id: item?.taluka_id,
    //     talukaName: item?.talukaName,
    //     distric_id: item?.distric_id,
    //     districtName: item?.districtName,
    //   }
    // }) : null;
    // setGramPanchayatArray(arr);
    // // console.log("Get ALL GP::::", res);
  }

  const fetchModule = async () => {
    setLoader(true)
    const result = await getAllTrainingModuleData()
    // console.log("Result", result)
    const arr = result !== "No data found" && result?.map(item => {
      return {
        label: item?.trainingModuleName,
        value: item?.trainingModuleName,
        _id: item?._id,
        // _id: item?.trainingModule_id,
      }
    })
    setTypeArray(arr)
    setLoader(false);

    // console.log("ModuleList", result)

    // ----------------------------------------------------------------------------------------------------------
    // const url = BASE_URL + "/trainingassigntrainer/trainingassigntrainer/grampanchayat/module/list";
    // const payload = {
    //   trainer_id: userDetails?._id,
    //   distric_id: userDetails?.preferredDistrict_id[0]
    // }
    // const opt = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(payload),
    // }
    // fetch(url, opt)
    //   .then(res => {
    //     if (res.status === 200)
    //       return res.json();
    //     else {
    //       alert("Error while getting Module !");
    //       setLoader(false);
    //     }
    //   })
    //   .then(data => {
    //     // console.log("Data:PPPPPP::;", data)
    //     // setModuleRes(data);
    //     const arr = data !== "No data found" && data?.map(item => {
    //       return {
    //         label: item?.trainingModuleName,
    //         value: item?.trainingModuleName,
    //         _id: item?.trainingModule_id,
    //       }
    //     })
    //     setTypeArray(arr)
    //     setLoader(false);
    //   })
    //   .catch(err => {
    //     // console.log(err); alert("Error while getting Module !");
    //     setLoader(false);
    //   })

    // -----------------------------------------------------------------------------------------------
    // // console.log("first")
    // const url = BASE_URL + "/trainingmodule/gettrainingmodulelist";
    // fetch(url, { method: "GET" })
    //   .then(res => {
    //     if (res.status === 200)
    //       return res.json();
    //     else {
    //       // alert("Error while getting Taluka !");
    //       // setLoader(false);
    //     }
    //   })
    //   .then(data => {
    //     // console.log("Data:::;", data)
    //     // setModuleRes(data);
    //     const arr = data?.map(item => {
    //       return {
    //         label: item.trainingModuleName,
    //         value: item.trainingModuleName,
    //         _id: item._id,
    //       }
    //     })
    //     setTypeArray(arr)
    //     // setLoader(false);
    //   })
    //   .catch(err => {
    //     // console.log(err); alert("Error while getting Taluka !");
    //     // setLoader(false);
    //   })
  }

  // const fetchCategory = async (list) => {
  //   setLoader(true);
  //   const url = BASE_URL + "/trainingcategory/gettrainingcategorylist";
  //   fetch(url, { method: "GET" })
  //     .then(res => {
  //       if (res.status === 200) {
  //         return res.json();
  //       }
  //       else {
  //         alert(" Error While getting Category List ! ");
  //         setLoader(false);
  //       }
  //     })
  //     .then(data => {
  //       // console.log("CategoryL:list::::", data)
  //       let arr = data !== undefined && data?.length > 0 ? data?.map(item => {
  //         return {
  //           _id: item?._id,
  //           name: item?.trainingCategoryName,
  //           qty: item?.minDocUpload,
  //           maxQty: item?.maxDocUpload,
  //           minDocAproved: item?.minDocAproved,
  //           type: item?.typeOfDoc?.length > 0 ? item?.typeOfDoc : "image/*"
  //         }
  //       }) : null;
  //       const abc = list !== undefined && list !== 'No data found' ? list?.map((item, index) => {
  //         return {
  //           _id: item._id,
  //           approved: item.category.filter(item => item.status === 'approve'),
  //           unprocessed: item.category.filter(item => item.status === 'unprocess'),
  //           rejected: item.category.filter(item => item.status === 'reject')
  //         }
  //       }) : undefined;
  //       // console.log("APPROVED REJECT COUNT :", abc)
  //       // console.log("APPROVED REJECT COUNT@ :", uploadDocList)
  //       setApproveRejectCount(abc)
  //       setCategoryArray(arr)
  //       setLoader(false);
  //     })
  //     .catch(err => {  setLoader(false); })
  // }


  const fetchCategory2 = async (list) => {
    setLoader(true);
    const url = BASE_URL + "/trainingcategory/gettrainingcategorylist/gpcat";
    const payload = {
      grampanchayat_id: gp_id,
      trainingModule_id: module_id
    }

    await fetch(url, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(payload) })
      .then(res => {

        if (res.status === 200) {
          return res.json();
        }
        else {
          alert(" Error While getting Category List ! ");
          setLoader(false);
        }
      })
      .then(data => {
        // console.log("CategoryLNEW:list::::", data)
        let arr = data !== undefined && data?.length > 0 ? data?.map(item => {
          return {
            _id: item?._id,
            name_id: item?.trainingCategory_id,
            name: item?.trainingCategoryName,
            qty: item?.minDocUpload,
            maxQty: item?.maxDocUpload,
            minDocAproved: item?.minDocAproved,
            type: item?.typeOfDoc?.length > 0 ? item?.typeOfDoc : "image/*",
            moduleName: item?.trainingModuleName,
            module_id: item?.trainingModule_id,
            isApproved: item?.isApproved,
          }
        }) : null;
        const abc = list !== undefined && list !== 'No data found' ? list?.map((item, index) => {
          return {
            _id: item._id,
            approved: item.category.filter(item => item.status === 'approve'),
            unprocessed: item.category.filter(item => item.status === 'unprocess'),
            rejected: item.category.filter(item => item.status === 'reject')
          }
        }) : undefined;
        // console.log("APPROVED REJECT COUNT :", abc)
        // console.log("APPROVED REJECT COUNT@ :", uploadDocList)
        setApproveRejectCount(abc)
        setCategoryArray(arr)
        setLoader(false);
      })
      .catch(err => {  setLoader(false); })
  }

  const uploadDocToAPI = async (docURL, event) => {
    const url = BASE_URL + "/trainerevidence/inserttrainerevidence";
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");

    let payLoad = new URLSearchParams();

    payLoad.append("trainer_id", userDetails?._id)
    let module_id = typeArray?.filter((item) => item.value === type)
    // payLoad.append("trainingModule_id", module_id[0]?._id)
    payLoad.append("trainingModule_id", tableRow.trainingModule_id)
    // Taken dummy district_id
    // let dist_id = gramPanchayatArray?.filter((item) => item.value === panchayat)
    let dist_id = gramPanchayatArray?.filter((item) => item.value === panchayat)
    // // console.log("&&&&&&", dist_id[0].distric_id)
    // payLoad.append("distric_id", dist_id[0].distric_id)
    payLoad.append("distric_id", tableRow.distric_id)
    let cat_id = categoryArray?.filter((item) => item.name === categoryName?.name)
    payLoad.append("trainingCategory_id", categoryName?.name_id)
    let gp_id = gramPanchayatArray?.filter((item) => item.value === panchayat)
    // payLoad.append("grampanchayat_id", gp_id[0]?._id)
    payLoad.append("grampanchayat_id", tableRow.grampanchayat_id)
    payLoad.append("trainingModuleName", type)
    payLoad.append("trainingCategoryName", categoryName?.name)
    payLoad.append("grampanchayatName", panchayat);
    payLoad.append("trainingDate", trainingDate);
    payLoad.append("trainerName", userDetails.firstName + " " + userDetails.lastName)
    payLoad.append("documentUrl", docURL)
    payLoad.append("categoryDoc", event?.type)
    payLoad.append("docSize", event?.size)
    payLoad.append('createdBy', userDetails?._id)
    // console.log("CATEGORRY", categoryName)
    payLoad.append('trainingModuleCatGp_id', categoryName?._id)

    const options = {
      method: 'POST',
      headers: header,
      body: payLoad,
    }

    // console.log("PayLoad", payLoad);
    fetch(url, options)
      .then(res => {
        // console.log("RESPONSE::::::", res)
        // console.log("RESPONSE DATA::::::", res.data)
        if (res.status === 200) {
          return res.json();
        }
        else {
          alert("Error While Uploading !")
          console.error("Error while Uploading to API");
        }
      })
      .then(async (data) => {
        // console.log("Uploading Data++++======>>>", data)
        await fetchUploadedDocList()
        if (data === "Not uploaded") {
          setImgUploadData(data)
          setImgUploadStatus(!imgUploadStatus);
        } else {
          setImgUploadStatus(!imgUploadStatus);
        }

      })
      .catch(err => {
        // console.log("Error Catched", err)
      })
  }

  const fetchUploadedDocList = async () => {
    const url = BASE_URL + "/trainerevidence/trainerevidence/grampanchayat";
    // console.log("FETCH CALL", gp_id, module_id)
    // // console.log("GPARRAY:",gramPanchayatArray)
    // let gp_id = gramPanchayatArray?.filter((item) => item.value === panchayat);
    // // console.log("GP", gp_id)
    // let module_id = typeArray?.filter((item) => item.value === type);
    // // console.log("GPModule", module_id)
    const payload = {
      grampanchayat_id: gp_id,
      // trainingModule_id: gp_id[0]?._id,
      trainingModule_id: module_id,
      trainingDate: trainingDate,
    }
    const opt = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
    fetch(url, opt)
      .then(res => {
        // console.log("Doc List:::{}{}{}---", res)
        return res.json();
      })
      .then(data => {
        // console.log("DOCData::::???", data);
        data !== undefined && data !== null && setUploadDocList(data)
        fetchCategory2(data)

      })
      .catch(err => {})
  }

  const getCategoryDocType = (cat) => {

    console.log("cat ==>", cat);
    // let x = categories.filter(item => item.name.toLowerCase() === cat.toLowerCase());typeOfDoc
    // if (x.length > 0) {
    //   setUploadType(x[0].type)
    //   setOpenModal(!openModal)
    // } else {
    //   alert("Category Data not found.")
    // }
    setUploadType(cat?.type);
    setOpenModal(!openModal);
  }
  const compareDate = (from_date) => {
    let d1 = moment(new Date()).format("DD-MM-YYYY")
    let d2 = moment(from_date).format("DD-MM-YYYY")

    let a = moment(new Date('2022/9/20')).format("DD-MMM-YYYY")
    let b = moment(new Date('2023/9/20')).format("DD-MMM-YYYY")
    // console.log("DATE--->>>>::::", a > b)

    let trainingDate = d2.split('-')
    let today = d1.split('-')

    if (today[2] >= trainingDate[2]) {

      if (today[2] === trainingDate[2]) {

        if (today[1] >= trainingDate[1]) {

          if (today[1] === trainingDate[1]) {

            if (today[0] >= trainingDate[0]) {
              return true;
            } else {
              return false;
            }

          } else {
            return true;
          }

        } else {
          return false;
        }
      } else {
        return true;
      }

    } else {
      return false;
    }







    // // console.log("Today", d1)
    // // console.log("From Date", d2)
    // if (d2 < d1) {
    //   // console.log("d1", d2 < d1)
    //   return true;
    // }
    // else if (d2 === d1) {
    //   // console.log("d2", d2 === d1)
    //   return true;
    // }
    // else {
    //   // console.log("ELSE", d2 === d1)
    //   return false;
    // }
  }

  console.log("imgUploadData", imgUploadData)

  const openComment = (row) => {
    setShowComment(row);
    setCommentModal(!commentModal);
  }

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Upload Training Document</Typography>
      </center>

      {tableRow ? null : 
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: "wrap", marginBottom: 50 }}>
        {/* From Date */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={fromDate}
              onChange={(newValue) => {
                setFromDate(newValue);
                setMinimumDate((newValue?.$M + 1) + "/" + newValue?.$D + "/" + newValue?.$y)
                // // console.log(newValue.$D + "/" + (newValue.$M + 1) + "/" + newValue.$y)
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {fromDateError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select From Date</span> : null}
        </FormControl>
        {/* To Date */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              style={{ width: 250 }}
              label="To Date"
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              minDate={dayjs(minimumDate)}
              renderInput={(params) => <TextField {...params} />}
              className={classes.input}
            />
          </LocalizationProvider>
          {toDateError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select To Date</span> : null}
        </FormControl>
        {/* Gram Panchayat */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">School</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="School"
            value={panchayat}
            disabled={gramPanchayatArray ? false : true}
            onChange={(e) => { setPanchayat(e.target.value) }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {gramPanchayatArray && gramPanchayatArray.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.value}>
                  {item?.label}
                </MenuItem>
              )
            })}
          </Select>
          {PanchayatError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Gram School</span> : null}
        </FormControl>
        {/* Training Module */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Training Module</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Training Module"
            value={type}
            disabled={typeArray ? false : true}
            onChange={(e) => { setType(e.target.value) }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {typeArray && typeArray?.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.value}>
                  {item?.label}
                </MenuItem>
              )
            })}
          </Select>
          {typeError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Training Module</span> : null}
        </FormControl>
        {/* Session/Shift */}
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          {/* Session = Shift */}
          <InputLabel id="outlined-adornment-Bank">Timing</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Shift"
            value={session}
            onChange={(e) => { setSession(e.target.value) }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {Session && Session.map((item) => {
              return (
                <MenuItem key={item?.label} value={item?.value}>
                  {item?.label}
                </MenuItem>
              )
            })}
          </Select>
          {sessionError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Shift</span> : null}
        </FormControl>
        {/* Get category Button */}
        <Button sx={{ m: 1, marginRight: 3 }} variant='contained' disabled={tableRow ? true : false || loader ? true : false} style={{ fontSize: 14 }} onClick={() => { getTrainerData() }} >
          Get Data
        </Button>
      </div>}

      {/* Table */}
      {
        tableRow ? <Paper sx={{ overflow: 'hidden', width: '90%' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Sr. No.</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>School</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Assignment Date</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Shift</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Module</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}>1</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{tableRow?.grampanchayatName}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{dayjs(tableRow?.startDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{tableRow?.shift ? tableRow?.shift.toUpperCase() : '-'}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{tableRow?.trainingModuleName}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Button variant='contained' onClick={() => navigate('/trainerLayout/rolloutDetails', { state: tableRow })} style={{ margin: 5 }} >View</Button>
                    {/* <Button variant='contained' onClick={() => navigate('/trainerLayout/uploadDocument', { state: row })} style={{ margin: 5 }} >Upload Document</Button> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper> : null
      }

      <Paper sx={{ overflow: 'hidden', width: '90%', marginTop: 5, }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 20, }}>
          <div>
            <Typography style={{ marginLeft:48, fontSize: 30, fontWeight: 600 }}><u>Important Instructions</u></Typography></div>
          <div>
            <ul style={{ margin: 10, fontSize: 18, fontWeight: 400, listStyleType: 'none' }}>
              <li><span className={classes.redStar}>*</span> All pictures should be in clear light.</li>
              <li><span className={classes.redStar}>*</span> Uploading picture in each category is required.</li>
              <li><span className={classes.redStar}>*</span> Date, Time & Geo Stamping on uploaded picture is mandatory.</li>
            </ul>
          </div>
        </div>
      </Paper>



      {showCategory ? <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}> Categories </Typography> : ''}
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
        {loader ? <CircularProgress color="primary" /> : null}
        {showCategory ? renderCategory : ''}
        {/* <div className='statusCard' onClick={() => setOpenModal(!openModal)}>
          Progress
        </div> */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
        <Button variant='contained' onClick={() => navigate(-1)} style={{ margin: 5 }} >Back</Button>
        <Button variant='contained' onClick={() => fetchUploadedDocList()} style={{ margin: 5 }} >Reload</Button>
        {/* <Button variant='contained' onClick={ () => navigate("/trainerLayout/training-report",{state:data}) } style={ tableRow?.trainingPlace && tableRow?.trainingPlace.length > 0 && tableRow?.feedbackformData && tableRow?.feedbackformData.length > 0 ? { margin:5,backgroundColor:'green' } : { margin:5 } }>Training Report</Button> */}
      </div>

      {/* Document uploading status */}
      <Snackbar open={imgUploading} autoHideDuration={6000} onClose={() => { }}>
        <Alert
          onClose={() => { }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Uploading...
        </Alert>
      </Snackbar>

      {/* Modal for document upload */}
      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          // setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.passmodal}>
          {/* Reject Comment */}
          <Modal
            className={classes.middlePosition}
            open={commentModal}
            onClose={(e) => {
              e.preventDefault();
              // setOpenModal(!openModal);
            }}
          >
            <Paper className={classes.passmodal2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h6' component='div'>Reject Comment</Typography>
                <IconButton aria-label="delete" onClick={e => {
                  e.preventDefault();
                  setCommentModal(!commentModal);
                }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ marginTop: '5%' }}>{showComment}</div>
              <center>
                <Button sx={{ m: 1, marginRight: 3 }} variant='contained' style={{ fontSize: 14 }} onClick={() => { setCommentModal(!commentModal); }} >
                  OK
                </Button>
              </center>
            </Paper>
          </Modal>
          <Modal
            className={classes.middlePosition}
            open={imgUploadStatus}
            onClose={(e) => {
              e.preventDefault();
              // setOpenModal(!openModal);
            }}
          >
            <Paper className={classes.passmodal2}>
              <Stack direction="row" justifyContent="space-between"
                alignItems="center" spacing={2}>
                <Stack direction="column">
                  {/* <Typography variant='h6' component='div'>{categoryName?.name}</Typography> */}
                </Stack>
                <IconButton aria-label="delete" onClick={e => {
                  e.preventDefault();
                  setImgUploadStatus(!imgUploadStatus);
                }}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              {imgUploadData === "Not uploaded" ? <div>Error Document is not Uploaded !</div> : <div>Document Uploaded Successfully !</div>}
              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button sx={{ m: 1, marginRight: 3 }} variant='contained' style={{ fontSize: 14 }} onClick={() => { setImgUploadStatus(!imgUploadStatus); }} >
                  OK
                </Button>
              </div>
            </Paper>
          </Modal>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              {/* <Typography variant='h6' component='div'>{categoryName?.name}</Typography> */}
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px', }}>Category</div>
              {/* <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>RCID</div> */}
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Module</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>School</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Date</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Upload Format</div>
              {/* <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>To Date</div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>{categoryName.name}</div>
              {/* <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>RCID_Number</div> */}
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>{type}</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>{panchayat}</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>{dayjs(fromDate).format('DD-MM-YYYY')}</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>{ uploadType?.length > 0 && uploadType.map(item => {
                if (item === 'image/*')
                  return <span>{"• IMAGE "}</span>
                else if (item === 'video/*')
                  return <span>{"• VIDEO "}</span>
                else
                  return <span>{"• EXCEL • PDF • WORD "}</span>
              })}</div>
              {/* <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>{dayjs(toDate).format('DD-MM-YYYY')}</div> */}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div>
              {/* categoryName ? categoryName.type :  */}
              <input accept={uploadType} disabled={imgUploading || !compareDate(fromDate)} className={classes.input} id="icon-button-file" type="file" multiple onChange={(e) => {
                // console.error("COMPARE TODAY DATE", new Date(fromDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0))
                // console.error("COMPARE FROM DATE1", new Date(fromDate).setHours(0, 0, 0, 0))
                console.error("COMPARE TODAY DATE2", e)

                if (compareDate(fromDate)) {
                  handleChange(e)
                }
                else {
                  alert("Cannot upload Documents For Upcomming Training Session");
                }
              }} style={{ display: "none" }} />
              <label htmlFor='icon-button-file'>
                <IconButton color="primary" disabled={imgUploading || !compareDate(fromDate)} aria-label="upload picture" component="span">
                  Upload Document
                </IconButton>
              </label>
            </div>
          </div>
          <h3>Documents</h3>
          {imgUploading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="primary" />
          </div> : null}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap', }}>
            {
              // uploadDocList !== undefined && uploadDocList?.length > 0 ? uploadDocList?.filter(item => item._id.toLowerCase() === categoryName?.name.toLowerCase())?.map((item,index) =>
              uploadDocList !== "No data found" && uploadDocList !== undefined && uploadDocList.length > 0 ? uploadDocList?.filter(item => item?._id?.toLowerCase() === categoryName?.name?.toLowerCase())[0]?.category?.map((item, index) =>
                // docList !== undefined && docList.length > 0 ? docList?.map((item, index) =>
                <div key={index} className='imgContainer'>

                  {/* COMMENT ICON */}
                  {item.status === "reject" ? <div className='deleteIcon' >
                    <IconButton size="large" aria-label="delete" onClick={e => {
                      openComment(item.approveRejectComment)
                    }}>
                      <TextsmsIcon color="error" />
                    </IconButton>
                  </div> : null}

                  {/* APPROVE STATUS ICON */}
                  {item.status === "approve" ? <div className='statusIcon'>
                    <IconButton size="large" aria-label="status" onClick={e => {
                      // console.log("Delete Clicked !!!!!")
                    }}>
                      <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                      {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                    </IconButton>
                  </div> : null}

                  {/* REJECT STATUS ICON */}
                  {item.status === "reject" ? <div className='statusIcon'>
                    <IconButton size="large" aria-label="status" onClick={e => {
                      // console.log("Delete Clicked !!!!!")
                    }}>
                      {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                      <CancelIcon style={{ fontSize: '40px' }} color="error" />
                    </IconButton>
                  </div> : null}

                  {/* UNPROCESS STATUS ICON */}
                  {item.status === "unprocess" ? <div className='statusIcon'>
                    <IconButton size="large" aria-label="status" onClick={e => {
                      // console.log("Delete Clicked !!!!!")
                    }}>
                      {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                      <HelpIcon style={{ fontSize: '40px' }} sx={{ color: '#ffd435' }} />
                    </IconButton>
                  </div> : null}



                  {item.status === "unprocess" ? <div className='textUnderProcess'>
                    Under Process
                  </div> : null}
                  {item.status === "approve" ? <div className='textApproved'>
                    Approved
                  </div> : null}
                  {item.status === "reject" ? <div className='textRejected'>
                    Rejected
                  </div> : null}
                  {/* {// console.log("ItemData", item.documentUrl)} */}
                  {item?.categoryDoc?.split('/')[0] === "image" ? <div className='img'>
                    <a target="_blank" href={item.documentUrl}><img src={item.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                  </div> : item?.categoryDoc?.split('/')[0] === "video" ? <div className='img'>
                    <a target="_blank" href={item.documentUrl}><img src={videoImg} style={{ height: '100%', width: '100%' }} /></a>
                  </div> : <div className='img'>
                    <a target="_blank" href={item.documentUrl}><img src={docImg} style={{ height: '100%', width: '100%' }} /></a>
                  </div>}
                </div>
              ) :
                <center>
                  <div>No Data Found</div>
                </center>
              // <div className='imgContainer'>
              //   {/* STATUS ICON */}
              //   <div className='statusIcon'>
              //     <IconButton size="large" aria-label="status" onClick={e => {
              //       // console.log("Delete Clicked !!!!!")
              //     }}>
              //       {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
              //       <CancelIcon style={{ fontSize: '32px' }} color="error" />
              //     </IconButton>
              //   </div>
              //   {/* DELETE ICON */}
              //   <div className='deleteIcon'>
              //     <IconButton size="large" aria-label="delete" onClick={e => {
              //       // console.log("Delete Clicked !!!!!")
              //     }}>
              //       <DeleteIcon color="error" />
              //     </IconButton>
              //   </div>
              //   <div className='textRejected'>
              //     Rejected
              //   </div>
              //   <div className='img'>
              //     <img src={img1} style={{ height: '100%', width: '100%' }} />
              //   </div>
              // </div>
              // -------------------------------------

            }
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  redStar: {
    color: 'red',
    fontSize: 22,
    fontWeight: 600,
    listStyleType: "none"
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal2: {
    width: '50%',
    // height: '20%',
    background: 'white',
    // borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '90%',
    height: '70%',
    background: 'white',
    // borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
    overflow: 'scroll'
  },
  profilemodal: {
    width: '1400px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  input: {
    width: '100%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGP: () => dispatch(Action.getAllgrampanchayatdata()),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    getSingleTraining: (data) => dispatch(Action.getSingleTraining(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);